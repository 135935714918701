@mixin cardShadow {
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.2);
}

@mixin cardShadowOff {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.compliance-container {
  .owner-text {
    position: absolute;
    top: 180px;
    left: 51px;
    font-size: 12px;
    color: #818181;
  }
  .document-content-container {
    position: relative;
    width: 100%;
    height: 800px;
    margin-top: 145px;
    margin-bottom: 30px;
    .document-content {
      position: absolute;
      width: 68%;
      height: 785px;
      overflow-y: scroll;
      background-color: #fff;
      border-radius: 2px;
      @include cardShadow();
      .document-title {
        position: relative;
        margin: 0 auto;
        width: auto;
        text-align: center;
        text-transform: capitalize;
        margin-top: 30px;
        background-color: #fff;
        padding: 30px;
        z-index: 2;
        border-bottom: 1px solid #ccc;
      }
      .document-title-line {
        position: absolute;
        width: 100%;
        top: 60px;
        z-index: 1;
        opacity: 0.3;
      }
      .document-section {
        padding-left: 30px;
        h3.section-title {
          font-size: 25px;
          position: relative;
          font-size: 18px;
          margin-top: 25px;
          margin-left: 10px;
          display: inline-block;
          padding-left: 30px;
          padding-bottom: 1px;
        }
        .section-body {
          position: relative;
          font-size: 12px;
          padding-left: 30px;
          max-width: 90%;
          margin-bottom: 30px;
          .subsection {
            margin-bottom: 30px;
            margin-top: 30px;
            h4.subsection-title {
              font-size: 18px;
            }
            h5.subsection-title {
              font-size: 18px;
              font-weight: 400;
              padding-left: 30px;
            }
            p {
              font-size: 15px;
              padding-left: 30px;
            }
            ol {
              margin-bottom: 30px;
              padding-left: 60px;
              li {
                font-size: 15px;
                p {
                  font-size: 15px;
                  padding: 10px;
                }
              }
            }
          }
        }
        .hide-section-body {
          height: 0px;
          overflow: hidden;
          margin-bottom: 0px;
        }
        ul.document-rule-list {
          list-style-type: none;
          margin: 0;
          li {
            .rule-title {
              position: relative;
              font-size: 18px;
              margin-top: 25px;
              margin-left: 10px;
              display: inline-block;
            }
            .toggle-hide {
              position: relative;
              width: 15px;
              height: 15px;
              display: inline-block;
              top: 2px;
              margin-left: 10px;
              cursor: pointer;
              background-size: 60%;
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url('../icons/black-arrow-icon.svg');
              transform: rotate(90deg);
            }
            .toggle-hide-active {
              transform: rotate(0deg);
            }
            .rule-body {
              position: relative;
              font-size: 12px;
              padding-left: 30px;
              max-width: 90%;
              margin-bottom: 50px;
              .section-title {
                position: relative;
                font-size: 15px;
                margin-bottom: 15px;
                margin-top: 20px;
                font-weight: 500;
                left: -30px;
              }
            }
            .hide-rule-body {
              height: 0px;
              overflow: hidden;
              margin-bottom: 0px;
            }
          }
        }
        .toggle-hide {
          position: relative;
          width: 15px;
          height: 15px;
          display: inline-block;
          top: 2px;
          margin-left: 10px;
          cursor: pointer;
          background-size: 60%;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('../icons/black-arrow-icon.svg');
          transform: rotate(90deg);
        }
        .toggle-hide-active {
          transform: rotate(0deg);
        }
      }
      p {
        padding: 30px;
      }
      ul.document-rule-list {
        list-style-type: none;
        margin: 0;
        padding: 40px;
        padding-top: 0px;
        padding-bottom: 10px;
        height: auto;
        // overflow: scroll;
        overflow-x: hidden;

        li {
          .rule-title {
            position: relative;
            font-size: 18px;
            margin-top: 25px;
            margin-left: 10px;
            display: inline-block;
          }
          .toggle-hide {
            position: relative;
            width: 15px;
            height: 15px;
            display: inline-block;
            top: 2px;
            margin-left: 10px;
            cursor: pointer;
            background-size: 60%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url('../icons/black-arrow-icon.svg');
            transform: rotate(90deg);
          }
          .toggle-hide-active {
            transform: rotate(0deg);
          }
          .rule-body {
            position: relative;
            font-size: 12px;
            padding-left: 30px;
            max-width: 90%;
            margin-bottom: 50px;
            .section-title {
              font-size: 15px;
              padding-left: 30px;
            }
            ol {
              margin-bottom: 30px;
              padding-left: 60px;
              li {
                font-size: 15px;
                p {
                  font-size: 15px;
                  padding: 10px;
                }
              }
            }
          }
        }
        .hide-section-body {
          height: 0px;
          overflow: hidden;
          margin-bottom: 0px;
        }
        ul.document-rule-list {
          list-style-type: none;
          margin: 0;
          li {
            .rule-title {
              position: relative;
              font-size: 18px;
              margin-top: 25px;
              margin-left: 10px;
              display: inline-block;
            }
            .toggle-hide {
              position: relative;
              width: 15px;
              height: 15px;
              display: inline-block;
              top: 2px;
              margin-left: 10px;
              cursor: pointer;
              background-size: 60%;
              background-position: center center;
              background-repeat: no-repeat;
              background-image: url('../icons/black-arrow-icon.svg');
              transform: rotate(90deg);
            }
            .toggle-hide-active {
              transform: rotate(0deg);
            }
            .rule-body {
              position: relative;
              font-size: 12px;
              padding-left: 30px;
              max-width: 90%;
              margin-bottom: 50px;
              .section-title {
                font-size: 15px;
                margin-bottom: 15px;
                margin-top: 20px;
                font-weight: 500;
              }
            }
            .hide-rule-body {
              height: 0px;
              overflow: hidden;
              margin-bottom: 0px;
            }
          }
        }
        .toggle-hide {
          position: relative;
          width: 15px;
          height: 15px;
          display: inline-block;
          top: 2px;
          margin-left: 10px;
          cursor: pointer;
          background-size: 60%;
          background-position: center center;
          background-repeat: no-repeat;
          background-image: url('../icons/black-arrow-icon.svg');
          transform: rotate(90deg);
        }
        .toggle-hide-active {
          transform: rotate(0deg);
        }
      }
      p {
        font-size: 18px;
        padding-top: 10px;
        padding-left: 30px;
        padding-bottom: 10px;
      }
      h3.section-title {
        font-size: 25px;
        position: relative;
        font-size: 18px;
        margin-top: 25px;
        margin-left: 10px;
        display: inline-block;
        padding-left: 30px;
        padding-bottom: 1px;
      }
    }
    .document-side-panel {
      position: absolute;
      right: 0px;
      width: 29%;
      height: 100%;

      .card {
        position: relative;
        width: 100%;
        background-color: #fff;
        border-radius: 2px;
        margin-bottom: 30px;
        @include cardShadow();
        span {
          position: relative;
          top: 15px;
          left: 15px;
        }
      }

      .status-card {
        height: 180px;
        padding-left: 2px;
        .status-indicator {
          top: 60px;
          width: 88%;
          margin: 0;
          padding: 0;
          left: 15px;
        }
        .status-text {
          position: absolute;
          top: 85px;
          width: 100%;
          text-align: left;
          font-size: 12px;
          line-height: 1.5;
          padding-left: 2px;
          text-transform: uppercase;
        }
      }

      .validation-logic-card {
        height: 260px;
      }

      .validation-logs-card {
        height: 290px;
        overflow-y: scroll;

        ul {
          position: relative;
          list-style-type: none;
          margin: 0 auto;
          padding: 0px;
          top: 35px;
          height: auto;
          left: 0px;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          padding-bottom: 30px;
          li {
            position: relative;
            padding: 0px;
            width: 90%;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 12px;
            span {
              position: relative;
              margin-bottom: 15px;
              padding: 0;
              height: 90%;
              width: 100%;
              padding-top: 5%;
              border-top: 1px solid #ccc;
            }
          }
        }
      }
    }
  }
  ul.categories-list {
    position: relative;
    top: 100px;
    list-style-type: none;
    margin: 0;
    margin-bottom: 320px;
    left: -40px;
    li {
      position: relative;
      width: 100%;
      background-color: #fff;
      padding: 15px;
      border-radius: 2px;
      margin-bottom: 10px;
      cursor: pointer;
      text-indent: 10px;
      overflow: hidden;
      @include cardShadow();
      .title-spacing {
        width: 45%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: absolute;
      }
      .status-text {
        position: absolute;
        top: 20px;
        left: 50%;
        span {
          position: absolute;
          font-size: 10px;
          width: 310px;
          color: #7f7f7f;
        }
        span.last-updated {
          position: absolute;
          top: 0px;
        }
        span.owner {
          position: absolute;
          white-space: nowrap;
          top: 12px;
        }
      }
    }
    li:hover {
      background-color: #fafafa;
    }
    li.get-next {
      // @include cardShadowOff();
      background-color: #f4f5f7;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .get-next:hover {
      background-color: #f4f5f7;
    }
    .applicable-status {
      position: absolute;
      right: 25px;
      font-size: 12px;
      color: #16b67b;
      font-weight: 500;
      padding-top: 3px;
      text-transform: uppercase;
    }
    .not-applicable {
      color: #7f7f7f;
    }
  }
  .status-indicator {
    position: absolute;
    right: 30px;
    width: 250px;
    height: 15px;
    background-color: #f4f5f7;
    top: 18px;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #e0123c;
    .status {
      height: 15px;
      width: 0%;
    }
    .green-status {
      background-color: #16b67b;
    }
    .yellow-status {
      background-color: #ffc73a;
    }
    .red-status {
      background-color: #e0123c;
    }
  }
  .status {
    height: 15px;
    width: 0%;
  }
  .green-status {
    position: absolute;
    right: 30px;
    width: 250px;
    height: 15px;
    background-color: #f4f5f7;
    top: 18px;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #16b67b;
  }
  .yellow-status {
    position: absolute;
    right: 30px;
    width: 250px;
    height: 15px;
    background-color: #f4f5f7;
    top: 18px;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #ffc73a;
  }
  .red-status {
    position: absolute;
    right: 30px;
    width: 250px;
    height: 15px;
    background-color: #f4f5f7;
    top: 18px;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #e0123c;
  }
}

.rules-container {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 300px;
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 200px;
  @include cardShadow();
  span {
    position: relative;
    top: 15px;
    left: 15px;
  }
  .rule-details-button {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 15px;
    top: 15px;
    // background-color: #eee;
    cursor: pointer;
  }
  ul.column-headers {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0px;
    list-style-type: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    background-color: #fafafa;
    border-top: 1px solid #eee;
    li {
      position: absolute;
      height: 35px;
      width: 120px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .rule-regulator {
      left: 3%;
    }
    .rule-regulation {
      left: 12%;
    }
    .rule-document {
      left: 21.5%;
    }
    .rule-title {
      left: 32%;
    }
    .rule-number {
      left: 63%;
    }
    .status-review {
      left: 75%;
    }
    .status-validation {
      left: 86%;
    }
  }

  ul.rule-items {
    position: relative;
    width: 100%;
    top: 0px;
    left: 0px;
    height: auto;
    list-style-type: none;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 91px;
    li {
      position: relative;
      width: 100%;
      height: 60px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-top: 1px solid #eee;
      ul {
        position: relative;
        width: 100%;
        list-style-type: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        li {
          position: absolute;
          height: 35px;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-top: 0px;
        }
        li.status {
          position: absolute;
          background-color: #eee;
          border-radius: 4px;
          width: 100px;
          text-align: center;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          margin-top: 0px;
          color: #ffb600;
          border: 1px solid #ffb600;
          background-color: #fff8e8;
        }
        .rule-regulator {
          left: 3%;
        }
        .rule-regulation {
          left: 12%;
        }
        .rule-document {
          left: 21.5%;
        }
        .rule-title {
          left: 32%;
          width: 250px;
        }
        .rule-number {
          left: 63%;
        }
        .review {
          left: 75%;
        }
        li.status.review.passed {
          color: #16b67b;
          border: 1px solid #16b67b;
          background-color: #eaf9f3;
        }
        .validation {
          left: 86%;
        }
        li.status.validation.passed {
          color: #16b67b;
          border: 1px solid #16b67b;
          background-color: #eaf9f3;
        }
        li.status.validation.failed {
          color: #e0123c !important;
          border: 1px solid #e0123c;
          background-color: #fdf2f4;
        }
        li.status.validation.underway,
        li.status.validation.scheduled,
        li.status.validation.investigate {
          color: #ffc73a;
          border: 1px solid #ffc73a;
          background-color: #fff8e8;
        }
      }
    }
    li:hover {
      background-color: #fafafa;
    }
  }
}
