.monitoring-table {
  position: relative;
  width: 100%;
  min-height: 100px;

  .rule-status-item {
    cursor: pointer;
    span {
      //  background-color: #eee;
      padding: 5px 8px 5px 8px;
      border-radius: 4px;
      text-transform: uppercase;
      margin: 0;
      line-height: 0;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .MuiOutlinedInput-multiline {
    padding: 0;
    width: 200px;
  }
  textarea {
    font-size: 10px;
    line-height: 12px;
    margin: 0;
    padding: 5px 5px 5px 15px;
  }

  // .MuiTableRow-root {
  // }
}
