@mixin cardItem {
  position: relative;
  margin: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@mixin cardShadow {
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
  background-color: #fff;
  overflow: hidden;
}

.category-layout-standard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  top: 110px;
  left: -8px;

  .document-body-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .document-content {
      width: 70%;
      height: auto;
      height: 1100px;
      @include cardItem;
      @include cardShadow;
      overflow-y: scroll;

      .document-title {
        margin-top: 10px;
        font-size: 20px;
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 12px;
      }

      .line-break {
        width: 90%;
        height: 1px;
        background-color: #ccc;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .document-body {
        width: 90%;
        font-size: 14px;
      }
    }

    .document-side-panel {
      width: 30%;
      height: auto;
      height: 1100px;
      @include cardItem;
    }
  }
  .document-footer-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 98.6%;
    .footer-title {
      position: relative;
      margin-top: 30px;
      margin-bottom: 15px;
      left: 10px;
      font-size: 22px;
      text-align: left;
    }
    .footer-content {
      width: 100%;
      min-height: 200px;
      @include cardItem;
      @include cardShadow;
      margin-bottom: 120px;
    }
  }
}

.control-container,
.monitoring-container {
  .document-body-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0;

    .document-side-panel.row-alignment-setting {
      flex-direction: row;
      height: 160px;
      width: 100%;
      flex-grow: 1;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0;
      padding: 0;
      margin-top: 10px;
      left: 10px;
      .side-panel-card-item {
        margin-right: 20px;
        min-width: 300px;
        height: 150px;
      }
    }

    .document-content {
      width: 100%;
      height: auto;
      min-height: 300px;
      @include cardItem;
      @include cardShadow;
      .document-body {
        width: 100%;
      }
    }
  }
}

.control-table,
.monitoring-table {
  .sign-off-button {
    span {
      position: relative;
      white-space: nowrap;
    }
  }
}

.status-button {
  background-color: #eee;
  padding: 12px 8px 12px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1px;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  line-height: 0;
}

.failed {
  border: 1px solid #e0123c;
  color: #e0123c !important;
}

.awaiting-sign-off {
  background-color: #656565;
  color: #fff;
}

.signed-off {
  border: 1px solid #16b67b;
  background-color: #16b67b;
  color: #fff;
}

.passed {
  border: 1px solid #16b67b;
  color: #16b67b !important;
}

.pending {
  border: 1px solid #ffc73a;
  color: #ffc73a !important;
}

ul {
  .log-status {
    padding: 4px 10px 4px 10px;
    border-radius: 4px;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 5px;
  }

  .log-status-date {
    position: relative;
    white-space: nowrap;
    font-size: 14px !important;
    top: 1px;
    color: #000 !important;
  }

  .log-status-content {
    position: relative;
    top: 8px;
    min-height: 130px !important;
  }
}

.loading-tab {
  background-color: #eee;
  padding: 5px 8px 5px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  margin: 0;
  line-height: 0;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
}
.loading-icon {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../icons/loading-icon.svg');
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
