.rules-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .rules-popup-content {
    position: relative;
    width: 90%;
    height: 95%;
    max-height: 800px;
    max-width: 900px;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 5001;
    border-radius: 3px;
    padding: 30px;
    padding-top: 15px;
    -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
    -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
    color: #000;
    overflow: hidden;

    .close-popup {
      position: fixed;
      width: 40px;
      height: 40px;
      top: 10px;
      right: 10px;
      background-size: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/close-icon.svg');
      cursor: pointer;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  .rules-popup-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
  }
}

.rules-popup {
  .rules-popup-content {
    border-radius: 4px;
    background-color: #fafafa;
    .popup-modal-header {
      position: relative;
      top: 0px;
      width: 100%;
      height: 230px;
      background-color: #fff;
      z-index: 2;
      border-radius: 2px 2px 0px 0px;
      background-color: #fafafa;
      margin-bottom: 40px;
      .header-title {
        position: relative;
        top: 8px;
        z-index: 1;
        font-size: 18px;
        font-weight: 500;
        max-width: 70%;
      }
      .rule-status-menu {
        position: relative;
        width: 100px;
        height: 25px;
        background-color: #eee;
        top: 20px;
        left: 0px;
        border-radius: 4px;
        color: #ffc73a;
        border: 1px solid #ffc73a;
        background-color: #fff8e8;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .menu-options {
          position: absolute;
          width: 120px;
          height: auto;
          background-color: #fff;
          z-index: 3;
          top: 24px;
          left: -2px;
          border-radius: 4px;
          display: none;
          border: 1px solid #ccc;
          -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
          -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
          box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
          .option {
            position: relative;
            width: 100%;
            height: 30px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 15px;
            color: #000;
          }
          .option:hover {
            background-color: #eee;
            color: #000;
          }
        }
      }
      .rule-status-menu:hover {
        .menu-options {
          display: block;
        }
      }
      .validation-status {
        position: absolute;
        width: 85px;
        height: 25px;
        background-color: #eee;
        top: 8px;
        right: 2px;
        border-radius: 4px;
        color: #ffc73a;
        border: 1px solid #ffc73a;
        background-color: #fff8e8;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .passed {
        color: #16b67b;
        border: 1px solid #16b67b;
        background-color: #eaf9f3;
      }
      .failed {
        color: #e0123c;
        border: 1px solid #e0123c;
        background-color: #fdf2f4;
      }
      .underway,
      .scheduled,
      .investigate {
        color: #ffc73a;
        border: 1px solid #ffc73a;
        background-color: #fff8e8;
      }

      .validation-status-details {
        position: absolute;
        color: #000;
        text-align: right;
        right: 100px;
        font-size: 10px;
        font-weight: 400;
        top: 15px;
      }
      .validation-logs-dropdown {
        position: absolute;
        right: 1px;
        top: 40px;
        width: 300px;
        height: 400px;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
        -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
        box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
        padding: 30px;
        text-align: left;
        overflow-y: scroll;

        span {
          font-size: 16px;
          width: 90%;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          width: 100%;
          margin-top: 25px;
          li {
            position: relative;
            line-height: 14px;
            margin-bottom: 15px;
            height: auto;
            padding-top: 15px;
            border-top: 1px solid #eee;
          }
          .title {
            color: #000;
            font-size: 14px;
          }
          span {
            color: #595959;
            font-size: 11px;
          }
        }
      }
    }
    .popup-modal-content {
      position: relative;
      margin-top: 0px;
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;

      justify-content: flex-start;
      flex-direction: column;
      overflow: scroll;
      overflow-x: hidden;
      border-radius: 3px;
      border: 1px solid #eee;
      background-color: #fff;
      padding: 25px;
      .title {
        position: relative;
        margin-top: 20px;
        font-weight: 500;
        margin-bottom: 15px;
        .last-updated {
          position: absolute;
          left: 0px;
          font-size: 12px;
          text-align: right;
          font-weight: 400;
          top: 22px;
          color: #595959;
        }
      }
      .expand-text-area {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: #eee;
        border-radius: 4px;
        top: 0px;
        right: 0px;
        background-size: 30%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../assets/icons/black-arrow-icon.svg');
        cursor: pointer;
        z-index: 10;
      }
      .expand-text-area.expanded-state {
        transform: rotate(90deg);
        background-image: url('../../assets/icons/toggle-arrow-icon-hover.svg');
      }
      p {
        font-size: 12px;
        width: 95%;
        color: #595959;
        margin-top: -5px;
        padding-top: 0px;
      }
      .MuiOutlinedInput-multiline {
        padding-right: 0px !important;
      }

      textarea.input-text {
        position: relative;
        min-height: 300px;
      }
      .input-text {
        margin-top: 10px;
        background-color: #fff;
        width: 100%;
      }
      .rule-popup-button {
        position: relative;
        top: 35px;
        right: 2px;
        align-self: flex-end;
        margin-bottom: 80px;
      }
      .saving-button {
        opacity: 0.5;
        pointer-events: none;
      }.input[type=file]{
        background-color: #62529c;
        border: none;
        color: #fff;
        padding: 15px 30px;
        text-decoration: none;
        margin: 4px 2px;
        cursor: pointer;
       }.input[type=text] {
          padding:5px;
          border:2px solid #ccc;
          -webkit-border-radius: 5px;
          border-radius: 5px;
       }.visually-hidden {
          position: absolute !important;
          height: 1px;
          width: 1px;
          overflow: hidden;
          clip: rect(1px, 1px, 1px, 1px);
        }

        /* Separate rule for compatibility, :focus-within is required on modern Firefox and Chrome */
        input.visually-hidden:focus + label {
          outline: thin dotted;
        }
        input.visually-hidden:focus-within + label {
          outline: thin dotted;
        }

    }
  }
  .loading-modal-content {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .disable-modal {
    pointer-events: none;
  }
  .popup-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.2;
    z-index: 1;
  }
}
