.status-card {
  position: relative;
  left: 0px;
  width: 90%;

  .last-validated {
    font-size: 10px;
  }

  .status-indicator {
    position: relative;
    left: 0px;
    width: auto;
    height: 15px;
    background-color: #f4f5f7;
    top: 18px;
    border-radius: 10px;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #e0123c;
    .status {
      height: 15px;
      width: 0%;
    }
    .green-status {
      background-color: #16b67b;
    }
    .yellow-status {
      background-color: #ffc73a;
    }
    .red-status {
      background-color: #e0123c;
    }
  }
  .status-text {
    position: relative;
    top: 33px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
  }
}
