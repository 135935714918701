@mixin cardItem {
  position: relative;
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 30, 0.4);
  background-color: #fff;
  margin-bottom: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

.side-panel-card-item {
  position: relative;
  width: 100%;
  height: 200px;
  padding: 20px;
  @include cardItem;

  span {
    font-size: 16px;
    width: 90%;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 90%;
    margin-top: 25px;
    li {
      line-height: 14px;
      margin-bottom: 15px;
      padding-top: 15px;
      border-top: 1px solid #eee;
    }
    .title {
      color: #000;
      font-size: 14px;
    }
    span {
      color: #595959;
      font-size: 11px;
    }
  }
  ol {
    padding-right: 0px;
    padding-left: 35px;
  }
}
.apply-scroll {
  overflow-y: scroll;
}
