.rules-table {
  position: relative;
  width: 100%;
  min-height: 200px;

  span.status-item {
    padding: 12px 8px 12px 8px;
    border-radius: 3px;
    text-transform: uppercase;
    margin: 0;
    margin-top: 1px;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    text-align: center;
    line-height: 0;
    border: 1px solid #16b67b;
    color: #16b67b !important;
  }

  span.status-item.failed {
    border: 1px solid #e0123c;
    color: #e0123c !important;
  }

  span.status-item.passed {
    border: 1px solid #16b67b;
    color: #16b67b !important;
  }

  span.status-item.pending {
    border: 1px solid #ffc73a;
    color: #ffc73a !important;
  }

  .MuiTableRow-root {
    cursor: pointer;
  }
}
