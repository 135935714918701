ul.compliance-category-nav {
  position: relative;
  width: 800px;
  height: 50px;
  top: 120px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  left: 5px;
  li {
    float: left;
    margin-right: 25px;
    font-weight: 400;
    height: 30px;
    color: #818181;
    cursor: pointer;
  }
  li:hover {
    color: #ce1011;
    border-bottom: 3px solid #ce1011;
  }
  li.active {
    color: #ce1011;
    border-bottom: 3px solid #ce1011;
  }
}
