.document-item {
  position: relative;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  h4 {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 18px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
    .close-button {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      background-image: url('../../assets/icons/toggle-arrow-icon.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 35%;
      top: 9px;
      left: 5px;
      cursor: pointer;
      transition: transform 0.2s;
    }
    .close-button:hover {
      background-image: url('../../assets/icons/toggle-arrow-icon-hover.svg');
    }
    .active {
      transform: rotate(90deg);
      background-image: url('../../assets/icons/toggle-arrow-icon-hover.svg');
    }
  }
  .section-title {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: 18px;
    padding-left: 0px;
    color: #000;
  }
  .content-body {
    position: relative;
    margin: 0;
    padding: 0;
    padding-right: 40px;
    margin-top: 20px;
    font-size: 14px;
    padding-left: 50px;
    color: #595959;
  }
}
