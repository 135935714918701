@import url('./fonts.css');

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font: 16px Roboto, Helvetica, Arial, sans-serif;
}

body *,
body::before,
body:after {
  box-sizing: border-box;
}

#root {
  display: flex;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  color: #010101;
  -webkit-text-fill-color: #010101;
  transition: background-color 999999s;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover {
  color: #010101;
  -webkit-text-fill-color: #010101;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #010101;
}
